.task {
    max-width: 40vw;
    font-family: 'Source Code Pro';
    margin-left: 10px;
    font-size: large;
}

.task_card {
    border: 2px solid black;
    border-radius: 8px;
    margin: 1.5%;
    padding: 10px;
}

.error{
    height: 50vw;
    width: 100vw;
}

.task_input {
    margin-left: 1%;
}

.task_card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border: 1px solid #ccc;
    margin-bottom: 10px;
}

.content {
    display: flex;
    align-items: center;
}

.functions {
    margin-left: 10px;
}

.png {
    cursor: pointer;
}

.form-control {
    border: 1px solid grey;
}

.btn-success{
    border: 2.5px #0d6efd solid;
}

.user_icon{
    border: 2px white solid;
    border-radius: 12px;
}

.nickname{
    color: white;
    text-decoration: none;
    font-size: larger;
}

.checked {
    text-decoration: line-through;
    color: #999;
}
  
.my-3 {
    border: 5px #797979;
    border-style:groove;
    border-radius: 15px;
    padding: 10px;
}
