@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@500&display=swap');

:root {
  font-family: 'Source Code Pro', system-ui, Avenir, Helvetica, Arial, sans-serif!important;

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}

Link{
  text-decoration: none;
  color: white;
}